<template>
  <div ref="chart" class="NewsroomFactsViewsByDateAreaChart" />
</template>

<script>
import { parse } from 'date-fns'

/**
 * @module NewsroomFactsViewsByDateAreaChart
 */
import AreaChart from '@hypefactors/shared/js/components/charts/base/AreaChart'

export default {
  name: 'NewsroomFactsViewsByDateAreaChart',
  props: {
    chartData: {
      type: [Object, Array],
      default: () => ({})
    }
  },

  watch: {
    chartData: 'redrawChart'
  },

  mounted () {
    this.createChart()
  },

  methods: {
    createChart () {
      this.$chart = new AreaChart(
        this.$refs.chart,
        this.createSeries(),
        {
          title: { text: this.$t('components.newsroom_facts_views_by_date_area_chart.engagement') },
          yAxisLabel: this.$t('components.newsroom_facts_views_by_date_area_chart.views')
        }
      )
    },
    createSeries () {
      return [{
        name: this.$t('components.newsroom_facts_views_by_date_area_chart.views'),
        data: Object.entries(this.chartData).map(([date, value]) => {
          return [parse(date).getTime(), value['ga:pageviews']]
        })
      }]
    },
    redrawChart () {
      this.$chart.update(this.createSeries())
    }
  }
}
</script>

import { defaultColors } from '../components/charts/DefaultChartColors'

/**
 * @typedef {{color: string, colorClass: string, label: string, icon: string}} HF_DEVICE
 */

/**
 * Defines the types of devices and their chart properties
 * @type {{tablet: HF_DEVICE, desktop: HF_DEVICE, mobile: HF_DEVICE}}
 */
export const DEVICE_TYPES = {
  mobile: {
    label: 'general.mobile',
    color: defaultColors[1],
    colorClass: 'chart-charcoal',
    icon: 'laptop'
  },
  tablet: {
    label: 'general.tablet',
    color: defaultColors[2],
    colorClass: 'chart-grey',
    icon: 'tv'
  },
  desktop: {
    label: 'general.desktop',
    color: defaultColors[0],
    colorClass: 'chart-blue',
    icon: 'newspaper'
  }
}

export const DEVICE_ORDER = ['desktop', 'mobile', 'tablet']

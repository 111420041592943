<template>
  <date-range-picker-base
    v-model="value"
    :picker-options="pickerOptions"
    :shortcuts="dashboardTimeSpans"
    :editable-date-range-input="editableDateRangeInput"
    class="DashboardFiltersDateRangePicker"
    data-testid="Filter__DateRangePicker"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DateRangePickerBase from './DateRangePickerBase'

/**
 * @module DashboardFiltersDateRangePicker
 */
export default {
  name: 'DashboardFiltersDateRangePicker',
  components: { DateRangePickerBase },
  props: {
    pickerOptions: {
      type: Object,
      default: () => ({})
    },
    editableDateRangeInput: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dashboardTimeSpans: [
        { slug: 'two_weeks', label: '14_days' },
        { slug: 'month', label: 'mtd' },
        { slug: 'year', label: 'ytd' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getFilter']),
    value: {
      get () {
        return [
          this.getFilter('start') * 1000,
          this.getFilter('end') * 1000
        ]
      },
      set ([start, end]) {
        this.setTimeFilters({
          name: 'start',
          value: start
        })
        this.setTimeFilters({
          name: 'end',
          value: end
        })
      }
    }
  },
  methods: {
    ...mapActions('globalFilters', ['setTimeFilters'])
  }
}
</script>

import { mapGetters } from 'vuex'
import { debounceMixin } from '../../mixins/debounceMixin'
import { generateUniqueWatcher } from '../../utils/componentUtilities'

/**
 * @module MetricsDataProviderMixin
 */
/**
 * Returns a mixin that watches for a property and fetches data debounced
 * @memberOf module:MetricsDataProviderMixin
 * @param {string} [watchFor] - Vuex getter to watch for
 * @param {string} [vuexModule] - Vuex module to load the getter from
 * @return {Vue#ComponentOptions}
 */
export function metricsDataProviderMixinFactory ({ watchFor = 'appliedDashboardFilters', vuexModule = 'dashboard' }) {
  return {
    data () {
      return {
        isLoading: false,
        // overwrite in component to check before fetching data
        permission: true,
        cancelToken: null
      }
    },

    computed: {
      ...mapGetters(vuexModule, [watchFor]),
      $_permission () {
        return this.permission
      }
    },

    mixins: [debounceMixin(['fetchDataDebounced'])],

    props: {
      fetchWhen: {
        type: Boolean,
        default: true
      }
    },

    beforeDestroy () {
      this.cancelToken && this.cancelToken.cancel()
    },

    watch: {
      fetchWhen (newVal, oldVal) {
        if (newVal === oldVal || newVal === false) return
        this.fetchDataDebounced()
      },

      [watchFor]: {
        immediate: true,
        handler: generateUniqueWatcher('_fetchIfReady')
      }
    },

    methods: {

      /**
       * Fetches the data if the permissions allow for it
       * @returns {Promise<*>}
       */
      fetchDataDebounced () {
        // cancel any previous requests
        this.cancelToken && this.cancelToken.cancel()
        // if the component is being destroyed, stop
        if (this._isDestroyed || this._isBeingDestroyed) {
          return
        }
        // if we have no permission, just bail
        if (!this.authorizeBool(this.$_permission)) return Promise.resolve()
        this.cancelToken = this.$api.cancelToken()
        this.isLoading = true
        return this.fetchData()
          .catch((e) => {
            if (this.$api.isCancelToken(e)) return
            this.$displayRequestError(e)
          })
          .finally(() => {
            this.isLoading = false
          })
      },

      /**
       * Method that should be overridden in the implementing component
       * @returns {Promise<void>}
       */
      async fetchData () {
        throw new Error('fetchData method needs to be overwritten')
      },

      /**
       * Calls fetchDataDebounced only if parent is ready
       * @private
       */
      _fetchIfReady () {
        this.fetchWhen && this.fetchDataDebounced()
      }
    }
  }
}

<template>
  <div class="SummaryChartHolder">
    <div v-loading="chartLoading" class="m-b-s">
      <slot name="chart" />
    </div>
    <div
      class="columns is-multiline is-mobile is-variable-grid is-1"
      data-testid="SquareStatHolder"
    >
      <div class="column">
        <slot name="stat_1" />
      </div>
      <div class="column">
        <slot name="stat_2" />
      </div>
      <div class="column">
        <slot name="stat_3" />
      </div>
      <div v-if="$slots.stat_4" class="column">
        <slot name="stat_4" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module SummaryChartHolder
 */
export default {
  name: 'SummaryChartHolder',
  props: {
    chartLoading: {
      type: Boolean,
      default: false
    },
    statsLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <summary-chart-holder>
    <newsroom-facts-views-by-date-area-chart
      slot="chart"
      :chart-data="chartData.pageviews_by_date"
    />
    <data-card
      slot="stat_1"
      :title="$t('components.newsroom_facts_summary_totals.total_views')"
      size="medium"
      class="SquareTotalViews is-full-height"
      active
    >
      {{ abbr(totalViews) }}
    </data-card>
    <data-card
      slot="stat_2"
      :title="$t('components.newsroom_facts_summary_totals.unique_views')"
      size="medium"
      class="SquareTotalUniqueViews is-full-height"
    >
      {{ abbr(totalUniqueViews) }}
    </data-card>
    <data-card
      slot="stat_3"
      :title="$t('components.newsroom_facts_summary_totals.average_session')"
      size="medium"
      class="SquareAverageSessionDuration is-full-height"
    >
      <i18n path="components.newsroom_facts_summary_totals.per_second">
        <span slot="value">
          {{ abbr(averageSessionDuration) }}
        </span>
      </i18n>
    </data-card>
    <template slot="stat_4">
      <slot />
    </template>
  </summary-chart-holder>
</template>

<script>
import SummaryChartHolder from '@hypefactors/shared/js/components/charts/extended/SummaryChartHolder'
import NewsroomFactsViewsByDateAreaChart from '@/components/stories/NewsroomFactsViewsByDateAreaChart'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'

/**
 * Used mostly on the Newsroom and Story facts pages
 * @module BaseFactsSummaryTotalsSection
 */
export default {
  name: 'BaseFactsSummaryTotalsSection',

  components: { NewsroomFactsViewsByDateAreaChart, SummaryChartHolder },

  mixins: [AbbrMixin],

  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  computed: {
    totalViews () {
      return this.chartData.total_views
    },
    totalUniqueViews () {
      return this.chartData.total_unique_views
    },
    averageSessionDuration () {
      return this.chartData.avg_session_duration
    }
  }
}
</script>

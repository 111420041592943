<template>
  <dashboard-filters-v2 class="NewsroomFactsFilters">
    <template #secondary>
      <dashboard-filters-date-range-picker :picker-options="datePickerOptions" />
      <v-icon type="caret-down" :even="false" />
    </template>
  </dashboard-filters-v2>
</template>

<script>
import { startOfYesterday, subDays } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import DashboardFiltersDateRangePicker from '@hypefactors/shared/js/components/filters/DashboardFiltersDateRangePicker'

/**
 * @module NewsroomFactsFilters
 */
export default {
  name: 'NewsroomFactsFilters',
  components: { DashboardFiltersDateRangePicker },
  data () {
    return {
      datePickerOptions: {
        disabledDate (time) {
          return time.getTime() > (Date.now() - (1000 * 60 * 60 * 24))
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getFilter'])
  },
  mounted () {
    this.setInitialFilters()
  },
  methods: {
    ...mapActions('globalFilters', ['setTimeFilters']),
    async setInitialFilters () {
      const start = this.getFilter('start') * 1000
      const end = this.getFilter('end') * 1000
      const yesterday = startOfYesterday()

      if (!start || !end || start > yesterday || end > yesterday) {
        await this.setTimeFilters({
          name: 'start',
          value: subDays(yesterday, 7)
        })
        await this.setTimeFilters({
          name: 'end',
          value: yesterday
        })
      }
      this.$emit('ready')
    }
  }
}
</script>

<template>
  <table
    class="table is-full is-bordered"
    data-testid="NewsroomFactsTopSources"
  >
    <thead class="has-background-grey-lighter is-size-6 no-hover">
      <tr>
        <td class="is-narrow">
          {{ $t('components.newsroom_facts_top_sources.rank') }}
        </td>
        <td class="is-narrow">
          {{ $t('components.newsroom_facts_top_sources.views') }}
        </td>
        <td>{{ $t('components.newsroom_facts_top_sources.source') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(sourceData, sourceName, index) in chartData"
        :key="index"
      >
        <td>{{ index + 1 }}</td>
        <td class="has-text-right">
          {{ sourceData['ga:pageviews'] }}
        </td>
        <td>
          {{ sourceName }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/**
 * @module NewsroomFactsTopSources
 */
export default {
  name: 'NewsroomFactsTopSources',
  props: {
    chartData: {
      type: [Object, Array],
      default: () => ({})
    }
  }
}
</script>

<template>
  <div>
    <div class="columns is-aligned-middle is-mobile">
      <div class="column is-6-mobile is-5-tablet is-3-desktop">
        <table
          class="is-fullwidth table is-transparent has-no-borders has-cells-centered has-cells-vertically-centered"
          data-testid="NewsroomFactsDeviceDistribution__Table"
        >
          <tr
            v-for="data in preparedData"
            :key="data.icon"
          >
            <td>
              <div
                :class="[`has-background-${data.colorClass}`]"
                class="icon is-large is-round"
              >
                <v-icon :type="data.icon" size="lg" />
              </div>
              <div class="is-size-5">
                {{ $t(data.label) }}
              </div>
            </td>
            <td>
              <div
                :class="[`has-color-${data.colorClass}`]"
                class="has-text-weight-bold has-text-right"
              >
                <div class="is-size-3">
                  {{ data.views }}
                </div>
                <div class="is-size-4">
                  {{ percentageFormat(data.percentage) }}
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="column is-6-mobile is-7-tablet is-5-desktop">
        <div ref="chart" data-testid="NewsroomFactsDeviceDistribution__Chart" />
      </div>
    </div>
  </div>
</template>

<script>
import DonutChart from '@hypefactors/shared/js/components/charts/base/DonutChart'
import { abbrTimeseriesFormatter } from '@hypefactors/shared/js/components/charts/TooltipFormatter'
import { DEVICE_TYPES } from '@hypefactors/shared/js/constants/deviceTypes'
import PercentageMixin from '@hypefactors/shared/js/mixins/PercentageMixin'

/**
 * @module NewsroomFactsDeviceDistribution
 */
export default {
  name: 'NewsroomFactsDeviceDistribution',

  mixins: [PercentageMixin],

  props: {
    /**
     * @type {{ desktop: { 'ga:pageviews': number }, mobile: { 'ga:pageviews': number  }, tablet: { 'ga:pageviews': number  } }}
     */
    chartData: {
      type: [Object, Array],
      default: () => ({})
    },
    total: {
      type: Number,
      default: 0
    }
  },

  computed: {
    preparedData () {
      const retrieve = (v) => this.chartData[v] ? this.chartData[v]['ga:pageviews'] : 0

      const mobile = retrieve('mobile')
      const tablet = retrieve('tablet')
      const desktop = retrieve('desktop')

      return {
        mobile: {
          views: mobile,
          percentage: mobile / this.total,
          icon: DEVICE_TYPES.mobile.icon,
          colorClass: DEVICE_TYPES.mobile.colorClass,
          label: DEVICE_TYPES.mobile.label
        },
        tablet: {
          views: tablet,
          percentage: tablet / this.total,
          icon: DEVICE_TYPES.tablet.icon,
          colorClass: DEVICE_TYPES.tablet.colorClass,
          label: DEVICE_TYPES.tablet.label
        },
        desktop: {
          views: desktop,
          percentage: desktop / this.total,
          icon: DEVICE_TYPES.desktop.icon,
          colorClass: DEVICE_TYPES.desktop.colorClass,
          label: DEVICE_TYPES.desktop.label
        }
      }
    }
  },

  watch: {
    chartData: 'updateChart'
  },

  mounted () {
    this.initChart()
  },
  methods: {
    /**
     * Creates the series data for the chart
     * @return {{name: string, y: number}[]}
     */
    createSeries () {
      return Object.entries(this.preparedData).map(([device, values]) => {
        return {
          name: this.$t(DEVICE_TYPES[device].label),
          y: values.views,
          color: DEVICE_TYPES[device].color
        }
      })
    },

    /**
     * Creates the series for the chart
     * @return {{name: string, data: ({name: string, y: number}[]), showInLegend: boolean}[]}
     */
    extractSeries () {
      return [{
        name: this.$t('components.newsroom_facts_device_distribution.visits'),
        data: this.createSeries(),
        showInLegend: false
      }]
    },

    initChart () {
      this.$chart = new DonutChart(this.$refs.chart, this.extractSeries(), {
        title: this.total,
        subtitle: this.$t('components.newsroom_facts_device_distribution.visitors'),
        formatter: abbrTimeseriesFormatter,
        titleStyle: {
          fontSize: '3rem'
        }
      })
    },

    /**
     * Updates the chart with new series and changes the title.
     * Called when the chartValues prop changes.
     */
    updateChart () {
      this.$chart.update(this.extractSeries(), this.total)
    }
  }
}
</script>
